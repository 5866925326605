/* line 4, ../scss/_premiumpage.scss */

.premiumpage section h1,.premiumpage section h2,.premiumpage section h3,.premiumpage section h4{
	font-family: 'brandon-grotesque','Segoe UI',Helvetica,Arial,'Lucida Grande',sans-serif;
}
.premiumpage section h1{
  font-size:15px;
  line-height:22px;
  color: #32325D;
  text-transform:uppercase;
}
.premiumpage section h2{
  font-size:45px;
  line-height:50px;
  font-weight:bold;
  color: #32325D;
  margin-top:20px;
  margin-left:20px;
  margin-right:20px;
}
.premiumpage section p{
	margin-left:20px;
  margin-right:20px;
}
.premiumpage section h3{
  font-size:38px;
  line-height:48px;
  font-weight:bold;
  color: #32325D;
  overflow:hidden;
  text-overflow:ellipsis;
}
.premiumpage section.premiumfeatures h3{
	min-height:96px;
	margin:12px 0px;
	display:flex;
	align-items: center;
	justify-content:center;
	
}

.premiumpage section .text{
  font-size:18px;
  line-height:27px;
  font-family:Open Sans;
  color: #525F7F;
}

@media only screen and (min-width: 1024px) {
  .premiumpage section{
    padding:50px 0px;
    border-top:2px solid #EBEBEB;  
  }
}

/* line 19, ../scss/_premiumpage.scss */
section.premiumfeatures {
  text-align: center;
  margin-bottom:40px;
  word-break: break-word;
}
section.premiumfeatures>div{
	margin-top:40px;
}
section.premiumfeatures>div>div{
  margin:0px 2%;
  width:340px;
  display:inline-block;
  vertical-align:top;
}	
section.premiumfeatures>div>div img{
	width:250px;
	max-width: 50%;
}
section.premiumfeatures>div>div h2{
  font-family: 'brandon-grotesque','Helvetica Neue Light','Helvetica Neue',Helvetica,Arial,'Lucida Grande',sans-serif;
  font-weight: 400;
  font-size: 27px;
  margin-top: 15px;
  color:#3e3e3e;
}
body.premiumpage section.premiumfeatures>div>div .text{
  margin-top:10px;
  font-family: 'Open Sans','Helvetica Neue Light','Helvetica Neue',Helvetica,Arial,'Lucida Grande',sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height:30px;
}
@media only screen and (max-width: 767px) {
	.premiumpage section h2{
    font-size:40px;
  }
  .premiumpage section.premiumfeatures>div{
	 margin-top:0px;
  }
	.premiumpage section.premiumfeatures>div>div{
	  margin-top:0px;
	  width:90%;
	}
	.premiumpage section.premiumfeatures h3{
	  margin:30px 0 20px 0;
	}
	
}

body.premiumpage section{
  text-align:center;
  overflow: hidden;
}

.cartouche{
  text-overflow:hidden;
  overflow:hidden;
  border-radius: 24px;
  max-width:90%;
  width:270px;
  display:inline-block;
  box-shadow: 0px 3px 16px #00000029;
  text-align:left;
  margin:24px;
  padding-bottom: 20px;
  vertical-align: top;
}
.fakecartouche{
  max-width:90%;
  width:300px;
  display:inline-block;
  vertical-align:top;
  text-align:right;
  margin:24px;
  position:relative;
  top: 208px;
  
}
.cartouche .title{
  background-color:#ede3fb;
  text-align:center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom:20px;
  
}
.premiumpage section .cartouche h2{
 margin:20px 0px 0px 0px;
 font-size:42px;
 text-transform:none;
 
}
.premiumpage section .cartouche h3{
 margin:0px 0px 20px 0px;
 font-size:22px;
 text-transform:none;
 white-space:nowrap;
}

.cartouche .bullet,.fakecartouche .bullet{
  padding:0px 10px 0px 10px;
  line-height:48px;
  height:48px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: start;
  line-height: 1.2em;
  
}
.fakecartouche .bullet{
	font-size:18px;
  font-family:'Open Sans';
  color: #525F7F;
  overflow:hidden;
  display: flex;
  align-items: center;
  justify-content: end;
  line-height: 1.2em;
}
.fakecartouche, .cartouche .bullet.stroke{
	display:none;
}
.cartouche .bullet::before{
	color:#A673EB;
	content:'';
	font-weight:bold;
	font-size:30px;
	margin-right:10px;
	display:inline-block;
	vertical-align:middle;
   background-color:#A673EB;
   border-radius:50%;
   width:20px;
   height:20px;
   flex-shrink: 0;
}

.premiumtestimonials{
  text-align:left;
  display:inline-block;
  vertical-align:top;
  border-radius:16px;
  box-sizing:border-box;
  box-shadow: 0px 3px 6px #CCC;
  padding:16px;
  width:347px;
  max-width:90%;
  margin:5% 0 5% 5%;
}
@media only screen and (max-width: 375px) {
	.premiumtestimonials{
		width:300px;
		margin:5% 10%;
	}
}
	
@media only screen and (min-width: 1024px) {
	.fakecartouche{
	  display:inline-block;
	}
	.cartouche{text-align:center;font-size:0;margin:24px;}
	
  .cartouche .bullet{
	   justify-content: center;
  }
	.cartouche .bullet::before{
    margin-right:0px;
	  font-size:0;
  }
  .cartouche .bullet.stroke{
    display:block;
  }
  .cartouche .bullet.stroke::before{
    content:'-';
    font-size:36px;;
    background-color:unset;
    position:relative;
    color:#ccc;
    top:15px;
  }
}

@media only screen and (max-width: 767px) {
  body.premiumpage .premiumfeatures .column::before{
    content:' ';
    width:70px;
    margin-top:30px;
    border-top:1px solid #32325D;
    display: block;
    margin: 30px auto 0 auto;
  }
  body.premiumpage .premiumfeatures .line:first-of-type .column:first-child::before{
    display:none;
  }
}  

@media only screen and (max-width: 1023px) {
  /* line 4, ../scss/_premium-mobile.scss */
  body.premiumpage header {
    height: 567px;
  }
  /* line 5, ../scss/_premium-mobile.scss */
  body.premiumpage header h2.mobpunch {
    margin-bottom: 15px;
  }
  body.premiumpage section h1{
    font-size: 16px;
    margin-top:40px;
    margin-bottom:4px;
  }
  body.premiumpage section h2{
    margin-top:4px;
    margin-bottom:0px;
  }
  body.premiumpage section h3{
    margin-top:16px !important;
    margin-bottom:10px !important;
    font-size: 30px;
    
  }
  body.premiumpage section {
    border-bottom:1px solid #EBEBEB;
  }
  body.premiumpage .premiumfeatures {
    border-bottom:1px solid #EBEBEB;
    margin-bottom: 0px;
    padding-bottom: 30px;
    
  }
  body.premiumpage .premiumfeatures img{
    margin-top:40px;
    height:150px;
    width: unset;
    max-width: unset;
  }
  body.premiumpage .premiumfeatures .line:first-child .column:first-child img{
    margin-top:52px;
  }
  .cartouche {
    margin: 24px;
  }
  .cartouche.freemium h3{
    display:none;
  }
  body.premiumpage .cartouche {
    width:40%;
    min-width:300px;
  }
  body.premiumpage .cartouche .title {
    padding-top: 1px;
    padding-bottom: 1px;
    margin-bottom: 0px;
  }
  body.premiumpage .cartouche h1{
    margin:16px auto;
    font-size:16px;
  }
  body.premiumpage .cartouche h2{
    margin:16px auto;
    font-size:42px;
  }
  body.premiumpage .cartouche h3{
    margin:16px auto;
  }
  .bigbtn.white{
    padding: 12px 20px;;
    font-size:14px;
    font-weight:bold;
    font-family: 'brandon-grotesque','Segoe UI',Helvetica,Arial,'Lucida Grande',sans-serif;
  }
  .footer>nav>div>a {
    font-size:16px;
    font-family: 'Open Sans','Segoe UI',Helvetica,Arial,'Lucida Grande',sans-serif !important;
  }
  
}
@media only screen and (max-width: 680px) {
  .cartouche {
    margin: 24px auto;
  }
}
  
