@import url(navbar.css);
@import url(footer.css);
@import url(head.css);
@import url(page_premium.css);
@import url(snow.css);
body {
    font-family: 'Open Sans','Segoe UI',Helvetica,Arial,'Lucida Grande',sans-serif;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
}
table td {
    border-collapse: collapse;
}
.container {
    width: 960px;
    display: table;
    margin: 0 auto;
}
.phone {
  box-shadow: 8px 10px 80px #32325D1F;
  border-radius:40px;
}
.phone.crop {
  border-radius:40px 40px 0px 0px;
}

.doubleCol {
    display: table;
    width: 100%;
    height:100%;
}
.bigbtn {
    border: solid 0px #4784EC;
    border-radius: 30px;
    background-color: #4784EC;
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 600;
}
a.bigbtn {
    font-size: 14px;
    padding: 12px 50px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
}
a:active, a.bigbtn:active, a.button.button:active {
    opacity: .5;
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
}
.sectionCalendar {
    height: 650px;
}
.section {
    overflow: hidden;
    position: relative;
    background-color: #fff;
    transition: background-color ease 1s;
}
.section:after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color: #000;
    opacity: 0;
    z-index: -1;
    transition: opacity ease 1s, z-index 1s linear 0s;
}
.section.with-separator{
    border-top:solid 1px #e4e4e4;
}

.before-view.section:after {
    opacity: 0.1;
    z-index: 0;
    transition: opacity ease 1s;
}
.featureGrid {
    max-width: 1080px;
    margin: auto;
    text-align: center;
}
.featureGridText {
    margin: 0 15px;
    text-align: center;
}
.featureGridItem {
    display: inline-block;
    vertical-align: top;
    width: 240px;
    margin: 25px 5%;
}
.stars {
    position: relative;
    top: 15px;
}
.ratingAuthor {
    position: relative;
    bottom: 10px;
    margin-bottom: 0;
}
.heroText {
    color: #ffffff;
}
.AvailableOn {
    margin: 20px 40px 40px 40px;
}
.textLeft {
    text-align: left;
    display: table-cell;
    vertical-align: middle;
    width: 380px;
    min-width: 380px;
    max-width: 380px;
    padding-right: 40px;
}
.textRight {
    text-align: left;
    display: table-cell;
    vertical-align: middle;
    width: 380px;
    min-width: 380px;
    max-width: 380px;
    padding-left: 40px;
}
.textCenter {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 2.5%;
    margin-left: auto;
    margin-right: auto;
}
.imgContainer {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.rating {
    font-style: unset;
}
.map-container {
    position: relative;
    left: 80px;
    width: 100%;
    max-width: 356px;
    margin: auto;
    min-height: 600px
}
.loc-pin {
    position: absolute;
}
.pin-boy {
    bottom: 40%;
    left: 28%;
    width: 41.6%;
    transition: bottom 700ms ease;
}
.in-view .pin-boy {
    bottom: 20%;
}
.pin-bank {
bottom: 47%;
left: 58%;
width: 27%;
}
.pin-dad {
    bottom: 25%;
    left: 130%;
    width: 20%
}
.pin-park {
    bottom: 2%;
    left: 150%;
    width: 13.5%
}
.narrowLocation {
    width: 100%;
    position: absolute;
    right: 0px;
    bottom: -80px;
}
/*RIPPLE*/

.circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: rgba(255, 187, 114, 0.9);
    position: absolute;
    bottom: 36.8%;
    left: 48.7%;
    -webkit-transition: height .25s ease, width .25s ease, bottom 700ms ease;
    transition: height .25s ease, width .25s ease, bottom 700ms ease;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.in-view .circle {
    bottom: 16.8%;
}
.circle:before, .circle:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 12px solid rgba(255, 187, 114, 0.5);
}
.circle:before {
    -webkit-animation: ripple 2s linear infinite;
    animation: ripple 2s linear infinite;
}
.circle:after {
    -webkit-animation: ripple 2s linear 1s infinite;
    animation: ripple 2s linear 1s infinite;
}
@-webkit-keyframes ripple {
    0% {
        -webkit-transform: scale(1);
    }
    75% {
        -webkit-transform: scale(5);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(6);
        opacity: 0;
    }
}
@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(5);
        opacity: 1;
    }
    100% {
        transform: scale(6);
        opacity: 0;
    }
}
.textCenter+.imgContainer .img {
    margin: 0 auto;
}
.doubleCol.imgContainer:first-child {
    display: table-cell;
    text-align: left;
}
.doubleCol.imgContainer:first-child.img {
    max-width: 100%;
    width: 100%;
    /* because Firefox */
    height: auto;
    margin: 0 auto;
}
.doubleCol.rightcol {
    display: table-cell;
    vertical-align: middle;
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    padding-left: 40px;
}
.section h2 {
    font-family: 'Open Sans','Segoe UI',Helvetica,Arial,'Lucida Grande',sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.35;
    color: #ffffff;
}
.section h3 {
    font-family: 'Open Sans', 'Segoe UI', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    margin-bottom: 15px;
}
.section h4 {
    font-family: 'brandon-grotesque','Segoe UI',Helvetica,Arial,'Lucida Grande',sans-serif;
    font-size: 45px;
    font-weight: 800;
    color: rgb(255, 255, 255);
    line-height: 50px;
    margin: 0 auto 25px 0;
}
.section .white {
    color: #ffffff;
}
.section .blue {
    color: #4784EC;
}

.section .darkgrey {
    color: #32325D
}
.darkergrey {
    color: #505050;
}
.section .black {
    color: #000000;
}
.section .red {
    color: #cd040b;
}
.section.bg-map {
    background-image: url(../img/bkg_locate.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.section.bg-green {
    background-color: #6cc8c1;
}
/* Animation element*/

.animation-element {
    position: relative;
}
/* Bounce up animation */

.bounce-up .subject {
    /*opacity: 0;*/
    -moz-transition: all 700ms ease-out;
    -webkit-transition: all 700ms ease-out;
    -o-transition: all 700ms ease-out;
    transition: all 700ms ease-out;
    -moz-transform: translate3d(0px, 200px, 0px);
    -webkit-transform: translate3d(0px, 200px, 0px);
    -o-transform: translate(0px, 200px);
    -ms-transform: translate(0px, 200px);
    transform: translate3d(0px, 200px, 0px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}
.bounce-up.in-view .subject {
    /*opacity: 1;*/
    -moz-transform: translate3d(0px, 0px, 0px);
    -webkit-transform: translate3d(0px, 0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate3d(0px, 0px, 0px);
}
.bounce-down .subject {
    /*opacity: 0;*/
    -moz-transition: all 700ms ease-out;
    -webkit-transition: all 700ms ease-out;
    -o-transition: all 700ms ease-out;
    transition: all 700ms ease-out;
    -moz-transform: translate3d(0px, -200px, 0px);
    -webkit-transform: translate3d(0px, -200px, 0px);
    -o-transform: translate(0px, -200px);
    -ms-transform: translate(0px, -200px);
    transform: translate3d(0px, -200px, 0px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}
.bounce-down.in-view .subject {
    /*opacity: 1;*/
    -moz-transform: translate3d(0px, 0px, 0px);
    -webkit-transform: translate3d(0px, 0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate3d(0px, 0px, 0px);
}
.bounce-left .subject {
    /*opacity: 0;*/
    -moz-transition: all 1000ms ease-out;
    -webkit-transition: all 1000ms ease-out;
    -o-transition: all 1000ms ease-out;
    transition: all 1000ms ease-out;
    -moz-transform: translate3d(-50px, 0px, 0px);
    -webkit-transform: translate3d(-50px, 0px, 0px);
    -o-transform: translate(-50px, 0px);
    -ms-transform: translate(-50px, 0px);
    transform: translate3d(-50px, 0px, 0px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}
.bounce-left.in-view .subject {
    /*opacity: 1;*/
    -moz-transform: translate3d(-100px, 0px, 0px);
    -webkit-transform: translate3d(-100px, 0px, 0px);
    -o-transform: translate(-100px, 0px);
    -ms-transform: translate(-100px, 0px);
    transform: translate3d(-100px, 0px, 0px);
}
.bounce-right .subject {
    /*opacity: 0;*/
    -moz-transition: all 1000ms ease-out;
    -webkit-transition: all 1000ms ease-out;
    -o-transition: all 1000ms ease-out;
    transition: all 1000ms ease-out;
    -moz-transform: translate3d(0px, 0px, 0px);
    -webkit-transform: translate3d(0px, 0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}
.bounce-right.in-view .subject {
    /*opacity: 1;*/
    -moz-transform: translate3d(20px, 0px, 0px);
    -webkit-transform: translate3d(20px, 0px, 0px);
    -o-transform: translate(20px, 0px);
    -ms-transform: translate(20px, 0px);
    transform: translate3d(20px, 0px, 0px);
}
.bounce-leftFaster .subject {
    /*opacity: 0;*/
    -moz-transition: all 500ms ease-out;
    -webkit-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
    -moz-transform: translate3d(0px, 0px, 0px);
    -webkit-transform: translate3d(0px, 0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}
.bounce-leftFaster.in-view .subject {
    /*opacity: 1;*/
    -moz-transform: translate3d(-100px, 0px, 0px);
    -webkit-transform: translate3d(-100px, 0px, 0px);
    -o-transform: translate(-100px, 0px);
    -ms-transform: translate(-100px, 0px);
    transform: translate3d(-100px, 0px, 0px);
}
@keyframes slide {
    0% {
        left: 0;
        top: 0;
    }
    100% {
        left: 0px;
        top: -1000px;
    }
}
/* Scroll Down Mouse Animation */

@-webkit-keyframes scroll-ani {
    0% {
        opacity: 1;
        top: 50%;
    }
    15% {
        opacity: 1;
        top: 29%;
    }
    50% {
        opacity: 0;
        top: 29%;
    }
    100% {
        opacity: 0;
        top: 50%;
    }
}
@-moz-keyframes scroll-ani {
    0% {
        opacity: 1;
        top: 50%;
    }
    15% {
        opacity: 1;
        top: 29%;
    }
    50% {
        opacity: 0;
        top: 29%;
    }
    100% {
        opacity: 0;
        top: 50%;
    }
}
@keyframes scroll-ani {
    0% {
        opacity: 1;
        top: 50%;
    }
    15% {
        opacity: 1;
        top: 29%;
    }
    50% {
        opacity: 0;
        top: 29%;
    }
    100% {
        opacity: 0;
        top: 50%;
    }
}

.fade {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.80);
    color: #fff;
    padding: 10px;
}
#scrollArea::-webkit-scrollbar {
    display: none;
}
.storeTinyIcon {
    margin: 0px 0.5vw;
    color: #999999;
    white-space: nowrap;
    line-height: 30px;
    display: inline-block;
    text-decoration: none;
}
.storeTinyIcon:hover {
    color: #4784EC;
}
.storeTinyIcon * {
    vertical-align: middle;
}
@media (min-width: 767px) {
    .showOnNarrow {
        display: none;
    }
    .section {
        height: 50em;
    }
    .smallSection {
        height: unset !important;
    }
    .messageSection {
        height: 40em !important;
    }
    .container {
        max-width: 1000px;
        width:100%;
        height:100%;
    }
    .heroText>p {
        line-height: 1.5;
        font-size: 18px;
        font-weight: 400;
    }
}

#message .imgContainer, #multigroup .imgContainer, #todo .imgContainer, #location .imgContainer {
    vertical-align: bottom;
}
@media (min-width: 768px) {
    #calendar .heroText, #message .heroText, #multigroup .heroText {
        height: 500px;
    }
    #todo .heroText, #location .heroText, #photos .heroText {
        height: 600px;
    }
}
@media (max-width: 768px) {
    #calendar .img {
        width: 50%;
    }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
    .img2 {
        position: inherit;
    }
    #location {
        background: linear-gradient(rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), url(../img/bg_map_mobile.jpg) no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
    }
}
@media (max-width: 767px) {
    .section h4 {
        font-size: 30px;
        line-height: 130%;
        margin-bottom: 15px;
    }
    .section h2, .section p {
        font-size: 16px;
    }
    .section h3 {
        margin: 20px 0 10px 0;
        font-size: 14px;
    }
    .todoText {
        position: relative;
        top: unset;
    }
    .AvailableOn {
        padding-top: 0px;
        margin-top: -10px;
    }
    .storeTinyIcon {
        display: block;
    }
    .textLeft *, .textRight * {
        text-align: left;
    }
    .container {
        width: 90%;
    }
    .container, .doubleCol, .doubleCol div {
        text-align: center;
    }
    .apple {
        width: 44px
    }
    .google {
        width: 44px
    }
    .textLeft.heroText {
        margin-bottom: 20px
    }
    .textLeft, .doubleCol>.rightcol {
        display: block;
        width: auto;
        min-width: 100%;
        max-width: 800px;
        padding: 0;
        text-align: center;
    }
    .textLeft {
        min-width: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .textRight div {
        width: auto;
        min-width: 100%;
        max-width: 800px;
        padding: 0;
        text-align: center;
    }
    .textRight, .doubleCol>.rightcol {
        display: block;
        width: auto;
        min-width: 100%;
        max-width: 800px;
        padding: 0;
        text-align: center;
    }
    .textRight {
        margin-left: auto;
        margin-right: auto;
    }
    .textCenter div {
        display: block;
        width: auto;
        min-width: 100%;
        max-width: 800px;
        padding: 0;
        text-align: center;
    }
    .textCenter, .doubleCol>.rightcol {
        display: block;
        width: auto;
        min-width: 100%;
        max-width: 800px;
        padding: 0;
        text-align: center;
    }
    .textCenter {
        margin-left: auto;
        margin-right: auto;
    }
    img {
        float: none;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }
    .narrowCirclePin {
        position: relative;
        left: 50%;
    }
    .map-container {
        left: 0px;
        min-height: 450px;
    }
    .bg-map {
        background-position: right;
    }
    .imgContainer {
        display: block;
        width: auto;
        min-width: 100%;
        max-width: 100%;
        text-align: center;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }
    .imgContainer.Upper {
        padding-top: 0;
        padding-bottom: 40px;
    }
    .imgContainer.img {
        float: none;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .img.features {
        width: 135px;
        height: 113px;
    }
    .featureGridItem {
        min-width: 150px;
        max-width: 150px;
        width: 150px;
        height: auto;
        margin: 0 auto;
    }
    .featureGridText {
        padding: 10px 0px;
        font-size: 90%;
        margin: 15px 5px;
        font-weight: 600;
    }
    .imgContainer.hideOnNarrow {
        display: none;
    }
    .hideOnNarrow {
        display: none;
    }
    .hideOnNarrowForce {
        display: none !important;
    }
    .section.bg-map {
        background-image: url(../img/bkg_locate_mobile.jpg);
    }
    .sectionCalendar {
        height: unset;
    }
    .textCalendar {
        position: unset;
    }
    .textPhoto {
        position: unset;
    }
    .textMessage {
        position: unset;
    }
}

@media (max-width: 364px) {
    .SMobileIMGTodoGif {
        top: 383px !important;
    }
    .SMobileIMGTodoBlock {
        top: 300px !important;
    }
}