.footer *{
	box-sizing: border-box;
}
.footer {
  width:100%;
  height: 184px;
  background-color: #586481;
  display: flex;
  flex-direction: row;
  align-items:center;
}
.footer>div,.footer>a{
	padding:20px 0px;
  width:20%;
  text-align:center;
  text-decoration:none;
}
.footer>nav{
  flex: 1;
  text-align:center;
}
.footer>nav>div>a{
  font-size:18px;
	color:#fff;
  font-family: 'brandon-grotesque','Segoe UI',Helvetica,Arial,'Lucida Grande',sans-serif !important;
	text-decoration:none;
	padding:10px;
	display:inline-block;
	font-weight:400;
}
@media (max-width: 768px) {
	.footer {
	  height: auto;
    flex-direction: column;
	}
	.footer>div,.footer>a{
	  width:100%;
	}
}
@media (max-width: 400px) {
  .footer {
    height: auto;
    flex-direction: column;
  }
  .footer>nav>div>a{
    width:100%;
  }
}

