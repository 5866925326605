.snow{
  position:fixed;
  top: 0px;
  transform: translate3D(0,0,0);
  background-color:white;
  display:inline-block;
  border-radius:50%;
  width:1px;
  height:1px;
}

.snow0{
  animation: fall0 8s linear infinite;
  box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, .75);
}

.snow1{
  animation: fall0 4s linear infinite;
  box-shadow: 0px 0px 3px 3px rgba(255, 255, 255, .75);
}

.snow2{
  animation: fall0 3s linear infinite;
  box-shadow: 0px 0px 4px 4px rgba(255, 255, 255, .75);
}


@keyframes fall0 {
  0% {
  transform: translate3D(3vw,0,0);
  }
  40% {
  transform: translate3D(-5vw,40vh,0);
  }
  50% {
  transform: translate3D(-6vw,50vh,0);
  }
  60% {
  transform: translate3D(-5vw,60vh,0);
  }
  100% {
  transform: translate3D(5vw,100vh,0);
  }
}
@keyframes fall1 {
  from {
    transform: translate3D(0,0,0);
  }
  to {
    transform: translate3D(5vw,100vh,0);
  }
}
@keyframes fall2 {
  0% {
    transform: translate3D(0vw,0,0);
  }
  40% {
    transform: translate3D(-3vw,40vh,0);
  }
  50% {
    transform: translate3D(-4vw,50vh,0);
  }
  60% {
    transform: translate3D(-2vw,60vh,0);
  }
  100% {
    transform: translate3D(8vw,100vh,0);
  }
}
@keyframes fall3 {
  0% {
    transform: translate3D(2vw,0,0);
  }
  40% {
    transform: translate3D(0vw,35vh,0);
  }
  50% {
    transform: translate3D(-3vw,45vh,0);
  }
  100% {
    transform: translate3D(3vw,100vh,0);
  }
}
