.head {
    background-color: #fff;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 600px;
    height: 85vh;
    display: table;
    width: 100%;
    font-family: 'brandon-grotesque', sans-serif !important;
}

.head h1 {
    font-family: 'brandon-grotesque','Segoe UI',Helvetica,Arial,'Lucida Grande',sans-serif;
    font-size: 56px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    line-height: 120%;
    margin: 0 auto 25px 0;
}
.head h2 {
    color: #ffffff;
    font-family: 'brandon-grotesque','Segoe UI',Helvetica,Arial,'Lucida Grande',sans-serif;
    font-size: 26px;
}
@media (min-width: 1300px) {
  .head h1 {
    font-size: 4vw;
  }
  .head h2 {
    font-size: 2vw;
  }
}
@media (max-width: 420px) {
  .head h1 {
    font-size: 35px;
    margin: 0 10px 20px 10px;
  }
  .head h2{
    font-size: 20px;
  }
  .head h2, .head .bigbtn{
    margin: 0 10px;
  }
  .head .bigbtn{
    padding: 12px 20px;
    margin-top:20px;
  }
  
}

@media (max-width: 768px) {
    .head {
        background-image: url(../img/home_bg@2x.jpg);
    }
}
@media (min-width: 768px) {
    .head1{
      background-image: url(../img/cover_chores.jpg);
    }
    .head2{
      background-image: url(../img/cover_groceries_a.jpg);
    }
    .head3{
      background-image: url(../img/cover_groceries_b.jpg);
    }
    .head4{
      background-image: url(../img/cover_recipe.jpg);
    }
    .head5{
      background-image: url(../img/cover_moment.jpg);
    }
    .head6{
      background-image: url(../img/cover_organize.jpg);
    }
    .head7{
      background-image: url(../img/Cover_xmas1.jpg);
    }
    .head8{
      background-image: url(../img/Cover_xmas2.jpg);
    }
    .head9{
      background-image: url(../img/Cover_xmas3.jpg);
    }
}
@media (max-height: 660px) {
    .head {
        min-height: 440px;
        height: 95vh;
    }
}
.headsection {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    text-align: center;
}
.btn-head-sign {
    margin-top: 0px;
    display: inline-block;
}
.btn-head-sign:hover {
    background-color: #258ACA;
}
.arrow-down {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%);
    height: 35px;
    width:35px;
    display:inline-block;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    cursor: pointer;
    -webkit-animation: MoveUpDown 10s linear infinite;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-webkit-keyframes MoveUpDown {
    0% {
        bottom: 40px;
    }
    4% {
        bottom: 21px;
    }
    5% {
        bottom: 20px;
    }
    6% {
        bottom: 21px;
    }
    10% {
        bottom: 40px;
    }
    14% {
        bottom: 21px;
    }
    15% {
        bottom: 20px;
    }
    16% {
        bottom: 21px;
    }
    20% {
        bottom: 40px;
    }
    100% {
        bottom: 40px;
    }
}